import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";
import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import User from "@/types/User";
import Licence from "@/types/Licence";
import Membership from "@/types/Membership";
import Access from "@/types/Access";
import Seat from "@/types/Seat";
import Role from "@/types/Role";
import Auth from "@/types/Auth";
import Report from "@/types/Report";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userLicences: new Array<Licence>(),
    userMemberships: new Array<Membership>(),
    attachmentData: new Array<any>(),
    licenceSessions: new Array<any>(),
    onlineUsers: new Array<string>(),
    pendingLicences: new Array<Licence>(),
    installPackages: new Array<any>(),
    users: new Array<any>(),
    currentUser: null as User,
    inspectUser: null as User,
    inspectAuth: null as Auth,
    inspectLicence: null as Licence,
    inspectAccesses: new Array<Access>(),
    inspectSeats: new Array<Seat>(),
    inspectMembership: null as Membership,
    loading: false,
    snackbarText: null,
    snackbarColor: "",
    roles: new Array<Role>(),
    reportData: new Array<Report>(),
    startDate: new Date().toISOString().substr(0, 10),
    endDate: new Date().toISOString().substr(0, 10),
    licenceType: "",
    createdStatistics: false,
    inspectUserUnsub: () => void {},
    inspectLicenceUnsub: () => void {},
    inspectAccessesUnsub: () => void {},
    inspectSeatsUnsub: () => void {},
    inspectMembershipUnsub: () => void {},
    userLicencesUnsub: () => void {},
    userMembershipsUnsub: () => void {},
    rolesUnsub: () => void {},
    allUserUnsub: () => void {},
    pendingLicencesUnsub: () => void {},
    installPackagesUnsub: () => void {},
  },
  getters: {
    userLicences: (state) => {
      return state.userLicences;
    },

    userMemberships: (state) => {
      return state.userMemberships;
    },

    licenceSessions: (state) => {
      return state.licenceSessions;
    },

    pendingLicences: (state) => {
      return state.pendingLicences;
    },

    currentUser: (state) => {
      return state.currentUser;
    },

    inspectUser: (state) => {
      return state.inspectUser;
    },
    inspectLicence: (state) => (id: string) => {
      return state.userLicences.find((lic) => lic?.id === id);
    },

    inspectAccesses: (state) => {
      return state.inspectAccesses;
    },

    inspectSeats: (state) => {
      return state.inspectSeats;
    },

    inspectMembership: (state) => {
      return state.inspectMembership;
    },

    users: (state) => {
      return state.users;
    },

    onlineUsers: (state) => {
      return state.onlineUsers;
    },

    userEmail: (state) => (id: string) => {
      const user = state.users.find((user) => user.id === id);
      if (user) {
        return user.email;
      } else {
        return "Unknown user";
      }
    },

    userName: (state) => (id: string) => {
      const user = state.users.find((user) => user.id === id);
      if (user) {
        return user.name;
      } else {
        return "Unknown user";
      }
    },

    loading: (state) => {
      return state.loading;
    },

    snackbarText: (state) => {
      return state.snackbarText;
    },

    snackbarColor: (state) => {
      return state.snackbarColor;
    },

    attachmentData: (state) => {
      return state.attachmentData;
    },

    installPackages: (state) => {
      return state.installPackages;
    },

    roles: (state) => {
      return state.roles;
    },

    inspectAuth: (state) => {
      return state.inspectAuth;
    },

    reportData: (state) => {
      return state.reportData;
    },

    licenceType: (state) => {
      return state.licenceType;
    },

    startDate: (state) => {
      return state.startDate;
    },

    endDate: (state) => {
      return state.endDate;
    },

    createdStatistics: (state) => {
      return state.createdStatistics;
    },
  },
  mutations: {
    addLicence: (state, data) => {
      state.userLicences.push(data.licence);
    },

    setLicences: (state, data) => {
      state.userLicences = data;
    },

    setUserMemberships: (state, data) => {
      state.userMemberships = data;
    },

    setAttachmentData: (state, data) => {
      state.attachmentData = data;
    },

    setCurrentUser: (state, data) => {
      state.currentUser = data;
    },

    setInspectUser: (state, data) => {
      state.inspectUser = data;
    },

    setInspectAccesses: (state, data) => {
      state.inspectAccesses = data;
    },

    setInspectSeats: (state, data) => {
      state.inspectSeats = data;
    },

    setInspectMembership: (state, data) => {
      state.inspectMembership = data;
    },

    setUsers: (state, data) => {
      state.users = data;
    },

    setLoading: (state, data) => {
      state.loading = data;
    },

    setError: (state, data) => {
      state.snackbarColor = "error";
      state.snackbarText = data;
    },

    setSuccess: (state, data) => {
      state.snackbarColor = "success";
      state.snackbarText = data;
    },

    setUserLicencesUnsub: (state, data) => {
      state.userLicencesUnsub = data;
    },

    doUserLicencesUnsub: (state) => {
      state.userLicencesUnsub();
      state.userLicencesUnsub = () => void {};
      state.userLicences = new Array<Licence>();
    },

    setUserMembershipsUnsub: (state, data) => {
      state.userMembershipsUnsub = data;
    },

    doUserMembershipsUnsub: (state) => {
      state.userMembershipsUnsub();
      state.userMembershipsUnsub = () => void {};
      state.userMemberships = new Array<Membership>();
    },

    setInspectUserUnsub: (state, data) => {
      state.inspectUserUnsub = data;
    },

    doInspectUserUnsub: (state) => {
      state.inspectUserUnsub();
      state.inspectUserUnsub = () => void {};
      state.inspectUser = null;
    },

    setInspectAccessesUnsub: (state, data) => {
      state.inspectAccessesUnsub = data;
    },

    doInspectAccessesUnsub: (state) => {
      state.inspectAccessesUnsub();
      state.inspectAccessesUnsub = () => void {};
      state.inspectAccesses = new Array<Access>();
    },

    setInspectSeatsUnsub: (state, data) => {
      state.inspectSeatsUnsub = data;
    },

    doInspectSeatsUnsub: (state) => {
      state.inspectSeatsUnsub();
      state.inspectSeatsUnsub = () => void {};
      state.inspectSeats = new Array<Seat>();
    },

    setInspectMembershipUnsub: (state, data) => {
      state.inspectMembershipUnsub = data;
    },

    doInspectMembershipUnsub: (state) => {
      state.inspectMembershipUnsub();
      state.inspectMembershipUnsub = () => void {};
      state.inspectMembership = null;
    },

    setPendingLicences: (state, data) => {
      state.pendingLicences = data;
    },

    setInstallPackages: (state, data) => {
      state.installPackages = data;
    },

    setRoles: (state, data) => {
      state.roles = data;
    },

    setRolesUnsub: (state, data) => {
      state.rolesUnsub = data;
    },

    doRolesUnsub: (state) => {
      state.rolesUnsub();
      state.rolesUnsub = () => void {};
      state.roles = new Array<Role>();
    },

    setAllUserUnsub: (state, data) => {
      state.allUserUnsub = data;
    },

    doAllUserUnsub: (state) => {
      state.allUserUnsub();
      state.allUserUnsub = () => void {};
      state.users = new Array<any>();
    },

    setPendingLicencesUnsub: (state, data) => {
      state.pendingLicencesUnsub = data;
    },

    doPendingLicencesUnsub: (state) => {
      state.pendingLicencesUnsub();
      state.pendingLicencesUnsub = () => void {};
      state.pendingLicences = new Array<Licence>();
    },

    setInstallPackagesUnsub: (state, data) => {
      state.installPackagesUnsub = data;
    },

    doInstallPackagesUnsub: (state) => {
      state.installPackagesUnsub();
      state.installPackagesUnsub = () => void {};
      state.installPackages = new Array<any>();
    },

    setInpsectAuth: (state, data) => {
      state.inspectAuth = data;
    },

    setReportData: (state, data) => {
      state.reportData = data;
    },

    setLicenceType: (state, data) => {
      state.licenceType = data;
    },

    setStartDate: (state, data) => {
      state.startDate = data;
    },

    setEndDate: (state, data) => {
      state.endDate = data;
    },

    setCreatedStatistics: (state, data) => {
      state.createdStatistics = data;
    },
  },
  actions: {
    fetchUserLicences: async (context, userId) => {
      context.commit("doUserLicencesUnsub");

      const unsub = firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .collection("licences")
        .onSnapshot(
          (snapshot) => {
            const ownLicences: Array<Licence> = [];
            snapshot.forEach((doc) => {
              ownLicences.push(doc.data() as Licence);
            });

            context.commit("setLicences", ownLicences);
          },
          (error) => {
            context.commit("setError", error.message);
          }
        );

      context.commit("setUserLicencesUnsub", unsub);
    },

    fetchInspectAccesses: async (context, ids) => {
      context.commit("doInspectAccessesUnsub");

      const unsub = firebase
        .firestore()
        .collection("users")
        .doc(ids.userId)
        .collection("licences")
        .doc(ids.licenceId)
        .collection("accesses")
        .onSnapshot(
          async (snapshot) => {
            const accesses: Array<Access> = [];
            snapshot.forEach(async (doc) => {
              const access = doc.data() as Access;
              accesses.push(access);
            });

            context.dispatch("fetchAdditionalAccessData", accesses);
          },
          (error) => {
            context.commit("setError", error.message);
          }
        );

      context.commit("setInspectAccessesUnsub", unsub);
    },

    fetchAdditionalAccessData: async (context, access) => {
      await Promise.all(
        access.map(async (acc: Access) => {
          let user = undefined;
          if (acc?.userId)
            user = await firebase
              .firestore()
              .collection("users")
              .doc(acc?.userId)
              .get();
          const membership = await firebase
            .firestore()
            .collection("memberships")
            .doc(acc?.membershipId)
            .get();

          if (acc) {
            acc.userName = user?.data()?.name;
            acc.email = user?.data()?.email;
            acc.membershipType = membership.data()?.type;
            acc.membershipExpirationDate = membership.data()?.expirationDate;
          }
        })
      );

      context.commit("setInspectAccesses", access);
    },

    fetchInspectSeats: async (context, ids) => {
      context.commit("doInspectSeatsUnsub");

      const unsub = firebase
        .firestore()
        .collection("users")
        .doc(ids.userId)
        .collection("licences")
        .doc(ids.licenceId)
        .collection("seats")
        .onSnapshot(
          async (snapshot) => {
            const seats: Array<Seat> = [];
            snapshot.forEach(async (doc) => {
              const seat = doc.data() as Seat;
              seats.push(seat);
            });

            context.commit("setInspectSeats", seats);
          },
          (error) => {
            context.commit("setError", error.message);
          }
        );

      context.commit("setInspectSeatsUnsub", unsub);
    },

    fetchUserMemberships: async (context, userId) => {
      context.commit("doUserMembershipsUnsub");

      const unsub = firebase
        .firestore()
        .collection("memberships")
        .where("userId", "==", userId)
        .onSnapshot(
          (snapshot) => {
            const memberships: Array<Membership> = [];
            snapshot.forEach((doc) => {
              memberships.push(doc.data() as Membership);
            });

            context.commit("setUserMemberships", memberships);
          },
          (error) => {
            context.commit("setError", error.message);
          }
        );

      context.commit("setUserMembershipsUnsub", unsub);
    },

    fetchInspectMembership: async (context, membershipId) => {
      context.commit("doInspectMembershipUnsub");

      const unsub = firebase
        .firestore()
        .collection("memberships")
        .doc(membershipId)
        .onSnapshot(
          (snapshot) => {
            const membership = snapshot.data() as Membership;
            context.dispatch("fetchAdditionalMembershipData", membership);
          },
          (error) => {
            context.commit("setError", error.message);
          }
        );

      context.commit("setInspectMembershipUnsub", unsub);
    },

    fetchAdditionalMembershipData: async (context, membership) => {
      if (membership.userId) {
        const snapshot = await firebase
          .firestore()
          .collection("users")
          .doc(membership.userId)
          .get();
        const user = snapshot.data() as User;

        membership.ownerName = user?.name;
        membership.ownerEmail = user?.email;
      }

      context.commit("setInspectMembership", membership);
    },

    fetchRoles: async (context, membershipId) => {
      context.commit("setLoading", true);
      context.commit("doRolesUnsub");

      const unsub = firebase
        .firestore()
        .collection("roles")
        .onSnapshot(
          (snapshot) => {
            const roleArray = new Array<Role>();
            snapshot.forEach((doc) => {
              const role = doc.data() as Role;
              roleArray.push(role);
            });

            context.dispatch("fetchAdditionalRoleData", roleArray);
            context.commit("setLoading", false);
          },
          (error) => {
            context.commit("setError", error.message);
          }
        );

      context.commit("setRolesUnsub", unsub);
    },

    fetchAdditionalRoleData: async (context, roles) => {
      context.commit("setLoading", true);
      await Promise.all(
        roles.map(async (role: Role) => {
          if (role?.userIds) {
            role.users = Array<any>();
            for (const userId of role.userIds) {
              const user = context.getters.users.find(
                (user: User) => user?.id === userId
              );
              role.users.push({
                id: user?.id,
                name: user?.name,
                email: user?.email,
              });
            }
          }
        })
      );

      context.commit("setRoles", roles);
      context.commit("setLoading", false);
    },

    addUserMembership: async (context, memberships) => {
      context.commit("setLoading", true);
      try {
        for (let i = 0; i < memberships.accesses.length; i += 1) {
          const doc = await firebase
            .firestore()
            .collection("memberships")
            .doc();

          const membership = {
            id: doc.id,
            accessId: "",
            creationDate: memberships.creationDate,
            expirationDate: memberships.accesses[i].expirationDate,
            muexpirationDate: memberships.accesses[i].expirationDate,
            type: memberships.accesses[i].membershipType,
            userId: memberships.userId,
          };

          doc.set(membership);

          firebase.analytics().logEvent("membership_added", {
            userId: membership.userId,
            membershipId: membership.id,
          });
        }

        context.commit(
          "setSuccess",
          `Membership has been successfully modified.`
        );

        context.commit("setLoading", false);
      } catch (error) {
        context.commit("setLoading", false);
        context.commit("setError", error.message);
      }
    },

    addUserLicence: async (context, licence) => {
      context.commit("setLoading", true);
      try {
        const idToken = await firebase.auth().currentUser?.getIdToken();

        const response = await axios.post(
          `${process.env.VUE_APP_API_BASEURL}licence/create`,
          licence,
          {
            headers: { authorization: `Bearer ${idToken}` },
          }
        );

        context.commit("setLoading", false);
        if (response.status !== 200) {
          context.commit("setError", response.data.error.message);
        } else {
          context.commit("setSuccess", "Licence has been added successfully");

          firebase.analytics().logEvent("licence_added", {
            userId: licence.userId,
            licenceId: response.data.payload.id,
          });
        }
      } catch (error) {
        context.commit("setLoading", false);
        context.commit("setError", error.message);
      }
    },

    modifyLicence: async (context, data) => {
      try {
        const userId: string = context.getters.inspectUser.id;
        await firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .collection("licences")
          .doc(data.id)
          .update(data);
        context.commit("setSuccess", `Licence has been successfully modified.`);
        firebase.analytics().logEvent("licence_modified", { data: data });
      } catch (error) {
        context.commit("setError", error.message);
      }
    },

    modifyMembership: async (context, data) => {
      try {
        await firebase
          .firestore()
          .collection("memberships")
          .doc(data.id)
          .update(data);
        context.commit(
          "setSuccess",
          `Membership has been successfully modified.`
        );
      } catch (error) {
        context.commit("setError", error.message);
      }
    },

    fetchPendingLicences: async (context, data) => {
      try {
        context.commit("doPendingLicencesUnsub");

        const unsub = firebase
          .firestore()
          .collectionGroup("licences")
          .where("licenceStatus", "==", "pending")
          .onSnapshot(
            (snapshot) => {
              const licences = new Array<any>();
              snapshot.forEach((doc) => {
                const data = doc.data();
                data.ownerId = doc.ref.parent.parent?.id;
                licences.push(data);
              });

              context.commit("setPendingLicencesUnsub", unsub);
              context.dispatch("fetchAdditionalPendingLicenceData", licences);
            },
            (error) => {
              context.commit("setError", error.message);
            }
          );
      } catch (error) {
        context.commit("setError", error.message);
      }
    },

    fetchAdditionalPendingLicenceData: async (context, licences) => {
      await Promise.all(
        licences.map(async (lic: Licence) => {
          const user = await firebase
            .firestore()
            .collection("users")
            .doc(lic?.ownerId)
            .get();
          if (lic) {
            lic.ownerName = user.data()?.name;
            lic.ownerEmail = user.data()?.email;
          }
        })
      );

      context.commit("setPendingLicences", licences);
    },

    fetchLicenceSessions: async (context, data) => {
      if (!data.id) {
        throw new Error("No licence id was given");
      }

      try {
        const snapshot = await firebase
          .firestore()
          .collection("licenceSessions")
          .where("licenceId", "==", data.id)
          .get();
        snapshot.forEach((doc) => {
          const docData = doc.data();
          if (
            context.state.licenceSessions.filter((e) => e.id === docData.id)
              .length === 0
          ) {
            context.state.licenceSessions.push(docData);
          }
        });

        context.commit("setSuccess", "Licence sessions fetched successfully!");
      } catch (error) {
        context.commit("setError", error.message);
      }
    },

    fetchOnlineUsers: async (context) => {
      try {
        const online = new Array<string>();
        const snapshot = await firebase
          .firestore()
          .collection("licenceSessions")
          .where("end", ">", Date.now() - 300000)
          .get();
        snapshot.forEach((doc) => {
          const docData = doc.data();
          online.push(docData.userId);
        });

        context.state.onlineUsers = online;
      } catch (error) {
        context.commit("setError", error.message);
      }
    },

    fetchInstallPackages: async (context) => {
      try {
        context.commit("doInstallPackagesUnsub");
        const unsub = firebase
          .firestore()
          .collection("installs")
          .where("public", "==", true)
          .onSnapshot(
            async (snapshot) => {
              const installs = new Array<any>();

              snapshot.forEach(async (doc) => {
                const data = doc.data();

                const consteel = await firebase
                  .app()
                  .storage(process.env.VUE_APP_FB_INSTALLPACKAGES_STORAGEBUCKET)
                  .ref(`public/${data.id}/consteel`)
                  .listAll();
                const joint = await firebase
                  .app()
                  .storage(process.env.VUE_APP_FB_INSTALLPACKAGES_STORAGEBUCKET)
                  .ref(`public/${data.id}/joint`)
                  .listAll();
                let consteelUrl = "";
                let jointUrl = "";

                if (consteel.items[0]) {
                  consteelUrl = await consteel.items[0].getDownloadURL();
                }

                if (joint.items[0]) {
                  jointUrl = await joint.items[0].getDownloadURL();
                }

                data.consteelUrl = consteelUrl;
                data.jointUrl = jointUrl;

                installs.push(data);
              });

              context.commit("setInstallPackagesUnsub", unsub);
              context.commit("setInstallPackages", installs);
            },
            (error) => {
              context.commit("setError", error.message);
            }
          );
      } catch (error) {
        context.commit("setError", error.message);
      }
    },

    publishInstallPackage: async (context, installId) => {
      try {
        await firebase
          .firestore()
          .collection("installs")
          .doc(installId)
          .update({ published: true });
        context.commit(
          "setSuccess",
          `Install package has been published successfully`
        );
        firebase
          .analytics()
          .logEvent("install_package_published", { installId: installId });
      } catch (error) {
        context.commit("setError", error.message);
      }
    },
    unPublishInstallPackage: async (context, installId) => {
      try {
        await firebase
          .firestore()
          .collection("installs")
          .doc(installId)
          .update({ published: false });
        context.commit(
          "setSuccess",
          `Install package has been unpublished successfully`
        );
        firebase
          .analytics()
          .logEvent("install_package_unpublished", { installId: installId });
      } catch (error) {
        context.commit("setError", error.message);
      }
    },
    markInstallPackageAsBeta: async (context, installId) => {
      try {
        await firebase
          .firestore()
          .collection("installs")
          .doc(installId)
          .update({ isBeta: true });
        context.commit(
          "setSuccess",
          `Install package has been marked as beta successfully`
        );
      } catch (error) {
        context.commit("setError", error.message);
      }
    },
    unMarkInstallPackageAsBeta: async (context, installId) => {
      try {
        await firebase
          .firestore()
          .collection("installs")
          .doc(installId)
          .update({ isBeta: false });
        context.commit(
          "setSuccess",
          `Beta flag from install package has been successfully removed`
        );
      } catch (error) {
        context.commit("setError", error.message);
      }
    },

    deleteUserLicence: async (context, licenceId) => {
      try {
        const userId: string = context.getters.inspectUser.id;
        await firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .collection("licences")
          .doc(licenceId)
          .delete();
        context.commit("setSuccess", `Licence has been deleted successfully`);
        firebase.analytics().logEvent("licence_deleted", {
          userId: userId,
          licenceId: licenceId,
        });
      } catch (error) {
        context.commit("setError", error.message);
      }
    },

    deleteUserMembership: async (context, membershipId) => {
      try {
        const userId: string = context.getters.inspectUser.id;
        await firebase
          .firestore()
          .collection("memberships")
          .doc(membershipId)
          .delete();
        context.commit(
          "setSuccess",
          `Membership has been deleted successfully`
        );
        firebase.analytics().logEvent("membership_deleted", {
          userId: userId,
          membershipId: membershipId,
        });
      } catch (error) {
        context.commit("setError", error.message);
      }
    },

    fetchAttachmentData: async (context, data) => {
      const fileArray = new Array<any>();

      try {
        const folderRef = firebase
          .app()
          .storage(process.env.VUE_APP_FB_LICENCING_STORAGEBUCKET)
          .ref(`${data.userId}/${data.licenceId}/attachements`);

        const fileRefs = await folderRef.listAll();

        await Promise.all(
          fileRefs.items.map(async (file) => {
            const data = {
              name: file.name,
              url: await file.getDownloadURL(),
            };

            fileArray.push(data);
          })
        );
      } catch (error) {
        context.commit("setError", error.message);
      }

      context.commit("setAttachmentData", fileArray);
    },

    fetchInspectUser: async (context, userId) => {
      context.commit("doInspectUserUnsub");

      const unsub = await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.data()) {
              context.commit("setInspectUser", snapshot.data());
              context.dispatch("fetchAdditionalInspectUserData", snapshot.id);
            } else {
              context.commit("setError", "User not found");
            }
          },
          (error) => {
            context.commit("setError", error.message);
          }
        );

      context.commit("setInspectUserUnsub", unsub);
    },

    fetchReportData: async (context, data) => {
      context.commit("setLoading", true);
      try {
        const idToken = await firebase.auth().currentUser?.getIdToken();
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASEURL}report/usersreport`,
          {
            headers: { authorization: `Bearer ${idToken}` },
            params: data,
          }
        );
        context.commit("setLoading", false);
        if (response.status !== 200) {
          context.commit("setError", response.data.error.message);
        } else {
          context.commit("setReportData", response.data.payload.statistics);
        }
      } catch (error) {
        context.commit("setLoading", false);
        context.commit("setError", error.message);
      }
    },

    fetchAdditionalInspectUserData: async (context, userId) => {
      context.commit("setLoading", true);
      try {
        const idToken = await firebase.auth().currentUser?.getIdToken();

        const response = await axios.get(
          `${process.env.VUE_APP_API_BASEURL}auth`,
          {
            headers: { authorization: `Bearer ${idToken}` },
            params: { id: userId },
          }
        );

        context.commit("setLoading", false);
        if (response.status !== 200) {
          context.commit("setError", response.data.error.message);
        } else {
          const auth = response.data.payload.auth as Auth;
          context.commit("setInpsectAuth", auth);
        }
      } catch (error) {
        context.commit("setLoading", false);
        context.commit("setError", error.message);
      }
    },

    fetchSearchUser: async (context, email) => {
      const searchUsers = new Array<any>();
      const snapshot = await firebase
        .firestore()
        .collection("users")
        .where("email", "==", email)
        .get();
      if (snapshot.size === 0) {
        context.commit("setError", "User not found");
        return;
      }
      snapshot.forEach((user) => {
        searchUsers.push({
          id: user.data().id,
          email: user.data().email,
          name: user.data().name,
          isAdmin: user.data().isAdmin,
        });

        const newUsers = context.getters.users;
        searchUsers.forEach((el) => {
          const index = newUsers.findIndex((u: any) => u.email === el.email);
          if (index < 0) {
            newUsers.push(el);
          } else {
            newUsers[index] = el;
          }
        });
        context.commit("setUsers", newUsers);
      });
    },

    fetchAllUser: (context) => {
      if (process.env.NODE_ENV === "localhost") {
        return;
      }
      
      context.commit("doAllUserUnsub");

      const unsub = firebase
        .firestore()
        .collection("users")
        .onSnapshot(
          (snapshot) => {
            const userArray = new Array<any>();

            snapshot.forEach((user) => {
              const data = {
                id: user.data().id,
                email: user.data().email,
                name: user.data().name,
                isAdmin: user.data().isAdmin,
              };

              userArray.push(data);
            });
            context.commit("setAllUserUnsub", unsub);
            context.commit("setUsers", userArray);
          },
          (error) => {
            context.commit("setError", error.message);
          }
        );
    },

    addToRole: async (context, data) => {
      try {
        context.commit("setLoading", true);
        const user = await firebase
          .firestore()
          .collection("users")
          .where("email", "==", data.userEmail)
          .get();
        if (user.empty) {
          context.commit(
            "setError",
            `User with email ${data.userEmail} not found`
          );
          context.commit("setLoading", false);
          return;
        }

        const userId = user.docs[0].data().id;

        await firebase
          .firestore()
          .collection("roles")
          .doc(data.roleId)
          .update({
            userIds: firebase.firestore.FieldValue.arrayUnion(userId),
          });
        context.commit(
          "setSuccess",
          "User has been added to role successfully"
        );

        context.commit("setLoading", false);
      } catch (error) {
        context.commit("setLoading", false);
        context.commit("setError", error.message);
      }
    },

    removeFromRole: async (context, data) => {
      try {
        context.commit("setLoading", true);
        await firebase
          .firestore()
          .collection("roles")
          .doc(data.roleId)
          .update({
            userIds: firebase.firestore.FieldValue.arrayRemove(data.userId),
          });
        context.commit(
          "setSuccess",
          "User has been removed from role successfully"
        );

        context.commit("setLoading", false);
      } catch (error) {
        context.commit("setLoading", false);
        context.commit("setError", error.message);
      }
    },

    signIn: async (context, data) => {
      context.commit("setLoading", true);
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(data.email, data.password);
        context.commit("setLoading", false);
      } catch (error) {
        context.commit("setError", error.message);
        context.commit("setLoading", false);
      }
    },

    logout: async (context) => {
      context.commit("doUserLicencesUnsub");
      context.commit("doUserMembershipsUnsub");
      context.commit("doInspectUserUnsub");
      context.commit("doInspectAccessesUnsub");
      context.commit("doInspectSeatsUnsub");
      context.commit("doInstallPackagesUnsub");
      context.commit("doPendingLicencesUnsub");
      context.commit("doAllUserUnsub");
      await firebase.auth().signOut();
    },

    clearError: (context) => {
      context.commit("clearError");
    },

    setError: (context, error) => {
      context.commit("setError", error);
    },

    setSuccess: (context, message) => {
      context.commit("setSuccess", message);
    },

    doLicenceUnsubscribe: (context) => {
      context.commit("doLicenceUnsubscribe");
    },

    setCurrentUser: (context, data) => {
      context.commit("setCurrentUser", data);
    },
  },
});
