import * as firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_APIKEY,
  authDomain: process.env.VUE_APP_FB_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FB_DATABASEURL,
  projectId: process.env.VUE_APP_FB_PROJECTID,
  storageBucket: process.env.VUE_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FB_APPID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID,
};

export default {
  install: (Vue: any, options: firebase.Observer<any, Error>) => {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.auth().useDeviceLanguage();
    firebase.auth().onIdTokenChanged(options);
  },
};
