import { Md5 } from "ts-md5"

export default class HashColor {
  public static get(what: string) {
    const hue =
      Math.abs(
        (Md5.hashStr(what, true) as Int32Array).reduce(
          (prev, curr) => prev + curr
        )
      ) % 360;
    return this.hslToHex(hue, 50, 50);
  }

  private static hslToHex(h: number, s: number, l: number) {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n: number) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, "0"); // convert to Hex and prefix "0" if needed
    };

    return `#${f(0)}${f(8)}${f(4)}`;
  }
}
