import VueRouter, { Route } from "vue-router";
import store from "../store";

export default async (to: Route, from: Route, next: any) => {
  if (store.getters.currentUser) {
    next();
  } else {
    next("/signin");
  }
};
