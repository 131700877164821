import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VApp,
    [
      _vm.isAuthenticated
        ? _c(
            VNavigationDrawer,
            { attrs: { app: "", clipped: "", "mini-variant": "" } },
            [
              _c(
                VList,
                [
                  _c(
                    VListItem,
                    { attrs: { router: "", to: "/pending" } },
                    [
                      _c(
                        VListItemIcon,
                        [
                          _vm.pendingLicencesCount
                            ? _c(
                                VBadge,
                                {
                                  attrs: {
                                    color: "error",
                                    content: _vm.pendingLicencesCount
                                  }
                                },
                                [_c(VIcon, [_vm._v("mdi-reload-alert")])],
                                1
                              )
                            : _c(VIcon, [_vm._v("mdi-reload-alert")])
                        ],
                        1
                      ),
                      _c(
                        VListItemContent,
                        [_c(VListItemTitle, [_vm._v("Pending licences")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VListItem,
                    { attrs: { router: "", to: "/packages" } },
                    [
                      _c(
                        VListItemIcon,
                        [_c(VIcon, [_vm._v("mdi-package-variant")])],
                        1
                      ),
                      _c(
                        VListItemContent,
                        [_c(VListItemTitle, [_vm._v("Packages")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VListItem,
                    { attrs: { router: "", to: "/stats" } },
                    [
                      _c(
                        VListItemIcon,
                        [_c(VIcon, [_vm._v("mdi-chart-pie")])],
                        1
                      ),
                      _c(
                        VListItemContent,
                        [_c(VListItemTitle, [_vm._v("Statistics")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VListItem,
                    { attrs: { router: "", to: "/settings" } },
                    [
                      _c(
                        VListItemIcon,
                        [_c(VIcon, [_vm._v("mdi-cog-outline")])],
                        1
                      ),
                      _c(
                        VListItemContent,
                        [_c(VListItemTitle, [_vm._v("Settings")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isAuthenticated
        ? _c(
            VAppBar,
            { attrs: { app: "", "clipped-left": "" } },
            [
              _c(VImg, {
                staticClass: "d-none d-md-block",
                attrs: {
                  "max-height": "40",
                  "max-width": "40",
                  contain: "",
                  alt: "Consteel starter",
                  src: require("@/assets/lime.svg")
                },
                on: {
                  click: function($event) {
                    return _vm.onHome()
                  }
                }
              }),
              _c(
                VToolbarTitle,
                {
                  staticClass: "ma-2 d-none d-md-block",
                  on: {
                    click: function($event) {
                      return _vm.onHome()
                    }
                  }
                },
                [_vm._v("Lime v1.1")]
              ),
              _c(VSpacer),
              _vm.isAuthenticated
                ? _c(VAutocomplete, {
                    attrs: {
                      items: _vm.users,
                      outlined: "",
                      dense: "",
                      "item-text": "email",
                      "item-value": "id",
                      "hide-details": "",
                      "prepend-icon": "mdi-account-search-outline",
                      "single-line": "",
                      label: "Search user",
                      id: "userEmail",
                      name: _vm.randomAutocomplete,
                      "search-input": _vm.search,
                      "return-object": ""
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onUser()
                      },
                      "update:searchInput": function($event) {
                        _vm.search = $event
                      },
                      "update:search-input": function($event) {
                        _vm.search = $event
                      }
                    },
                    model: {
                      value: _vm.searchUser,
                      callback: function($$v) {
                        _vm.searchUser = $$v
                      },
                      expression: "searchUser"
                    }
                  })
                : _vm._e(),
              _c(VSpacer),
              _c(
                VAvatar,
                {
                  staticClass: "mr-4",
                  attrs: {
                    color: _vm.getColor(_vm.currentUserEmail),
                    size: "36"
                  }
                },
                [
                  _c("span", { staticClass: "white--text" }, [
                    _vm._v(_vm._s(_vm.currentUserInitial))
                  ])
                ]
              ),
              _vm.isAuthenticated
                ? _c(
                    VBtn,
                    {
                      attrs: { outlined: "", color: "info" },
                      on: { click: _vm.onLogout }
                    },
                    [_vm._v("Logout")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        VContent,
        [
          _c(
            VContainer,
            { attrs: { fluid: "" } },
            [
              _c(
                VSnackbar,
                {
                  attrs: {
                    outlined: "",
                    color: _vm.snackbarColor,
                    timeout: 2000,
                    right: true,
                    top: true
                  },
                  model: {
                    value: _vm.snackbarShow,
                    callback: function($$v) {
                      _vm.snackbarShow = $$v
                    },
                    expression: "snackbarShow"
                  }
                },
                [
                  _vm.snackbarColor === "error"
                    ? _c(
                        VAvatar,
                        {
                          staticClass: "mr-4",
                          attrs: { color: "rgba(0,0,0,0.1)" }
                        },
                        [
                          _c(
                            VIcon,
                            { attrs: { color: "error", dark: "" } },
                            [_vm._v("mdi-alert")]
                          )
                        ],
                        1
                      )
                    : _vm.snackbarColor === "success"
                    ? _c(
                        VAvatar,
                        {
                          staticClass: "mr-4",
                          attrs: { color: "rgba(0,0,0,0.1)" }
                        },
                        [
                          _c(
                            VIcon,
                            { attrs: { color: "success", dark: "" } },
                            [_vm._v("mdi-check-circle")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.snackbarText) + " ")
                ],
                1
              ),
              _c(
                "transition",
                { attrs: { name: "fade-transition" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }