








































































































































































































import Vue from "vue";
import AddAccessData from "../types/AddAccessData";

export default Vue.extend({
  name: "App",

  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    type: "",
    dialog: false,
    menu: false,
    step: 1,
    productName: "",
    accessMembershipType: "",
    accesses: new Array<AddAccessData>(),
    numberOfSeats: 1,
  }),

  computed: {
    loading() {
      return this.$store.getters.loading;
    },

    users() {
      return this.$store.getters.users;
    },
  },

  methods: {
    onSubmit() {
      const finalAccesses = new Array<{
        expirationDate: number;
        membershipType: string;
      }>();
      this.accesses.forEach((acc) => {
        finalAccesses.push({
          membershipType: acc.membershipType,
          expirationDate: new Date(acc.expiration).getTime(),
        });
      });

      const licenceData = {
        userId: this.$store.getters.inspectUser.id,
        type: this.type,
        productName:
          this.productName === "Consteel Joint" ? "csJoint" : this.productName,
        accesses: finalAccesses,
        availableSeatNumber: this.numberOfSeats,
      };

      this.$store.dispatch("addUserLicence", licenceData);
    },

    onAddAccess() {
      const accessData: AddAccessData = {
        id: this.accesses.length + 1,
        membershipType: this.accessMembershipType,
        expiration: this.date,
      };

      this.accesses.push(accessData);
    },

    onDeleteAccess(id: number) {
      this.accesses.splice(
        this.accesses.findIndex((access) => access.id === id),
        1
      );
    },

    capitalizeStr(str: string) {
      return str.charAt(0).toLocaleUpperCase() + str.slice(1);
    },
  },

  watch: {
    loading: function (lhs, rhs) {
      if (lhs === "true" && rhs === "false") this.dialog = false;
    },
  },
});
