








































































































































import Vue from "vue";
import User from "@/types/User";
import HashColor from "@/plugins/hashcolor";

export default Vue.extend({
  name: "App",

  data: () => ({
    sideNav: false,
    snackbarText: "",
    snackbarColor: "",
    snackbarShow: false,
    searchUser: null,
    randomAutocomplete: Math.random()
      .toString(36)
      .substring(7),
    search: null,
  }),

  computed: {
    menuItems() {
      let menuItems = [
        {
          icon: "mdi-account-arrow-right-outline",
          title: "Sign in",
          link: "/signin",
        },
      ];

      if (this.isAuthenticated) {
        menuItems = [
          {
            icon: "mdi-reload-alert",
            title: "Pending licences",
            link: "/pending",
          },
          { icon: "mdi-package-variant", title: "Packages", link: "/packages" },
          { icon: "mdi-chart-line", title: "Reports", link: "/stats" },
          { icon: "mdi-cog-outline", title: "Distributors", link: "/settings" },
          {
            icon: "mdi-cog-outline",
            title: "Role management",
            link: "/settings",
          },
        ];
      }

      return menuItems;
    },

    users() {
      return this.$store.getters.users;
    },

    pendingLicencesCount() {
      return this.$store.getters.pendingLicences.length;
    },

    currentUserEmail() {
      if (this.$store.getters.currentUser) {
        return this.$store.getters.currentUser.email;
      } else {
        return "";
      }
    },

    currentUserInitial() {
      if (this.$store.getters.currentUser) {
        const userName: string = this.$store.getters.currentUser.name;
        const array = userName.split(" ");
        let initials = "";

        array.forEach((element) => (initials += element.charAt(0)));

        if (initials.length === 0) {
          return "?";
        } else {
          return initials;
        }
      } else {
        return "";
      }
    },

    isAuthenticated() {
      return (
        this.$store.getters.currentUser !== null &&
        this.$store.getters.currentUser !== undefined
      );
    },

    error() {
      return this.$store.getters.error;
    },
  },

  methods: {
    onLogout() {
      this.$store.dispatch("logout");
      this.$router.push("/signin");
    },

    onDismissed() {
      this.$store.dispatch("clearError");
    },

    onHome() {
      this.searchUser = null;
      this.$router.push("/");
    },

    async onUser() {
      const email = this.search;
      await this.$store.dispatch("fetchSearchUser", email);
      this.searchUser = (this.$store.getters.users as Array<any>).find(
        (e) => e.email === email
      );
    },

    getColor(what: string) {
      return HashColor.get(what);
    },
  },

  watch: {
    searchUser(newUser: User) {
      if (!newUser) {
        return;
      }
      this.searchUser = null;
      this.$router.push(`/user/${newUser.id}`);
    },
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setError" || mutation.type === "setSuccess") {
        this.snackbarText = state.snackbarText;
        this.snackbarColor = state.snackbarColor;
        this.snackbarShow = true;
      }
    });
  },
});
