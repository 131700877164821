import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueClipboard from "vue-clipboard2";
import firebasePlugin from "@/plugins/firebase";
import * as firebase from "firebase/app";

import AlertComponent from "./components/Alert.vue";
import AddLicenceModal from "./components/AddLicenceModal.vue";

Vue.config.productionTip = false;

Vue.component("add-licence-modal", AddLicenceModal);
Vue.use(VueClipboard);
Vue.use(firebasePlugin, async (auth: firebase.User) => {
  if (auth) {
    const idToken = await auth.getIdTokenResult();

    const roleOk = idToken.claims.roles ? 
      idToken.claims.roles.includes("root") ||
      idToken.claims.roles.includes("licence_admin") ||
      idToken.claims.roles.includes("install_admin") : false;

    if (idToken.claims.roles && roleOk) {
      const user = await firebase
        .firestore()
        .collection("users")
        .doc(auth.uid)
        .get();
      const userData = user.data();

      if (userData) {
        store.dispatch("setCurrentUser", userData);

        store.dispatch("fetchAllUser");
        store.dispatch("fetchPendingLicences");
        store.dispatch("fetchInstallPackages");
      } else store.dispatch("setCurrentUser", null);
    } else {
      store.dispatch("setError", "Insufficient permissions");
    }
  } else {
    store.dispatch("setCurrentUser", null);
  }

  if (!("vue" in window)) {
    (window["vue" as any] as any) = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
